<!--
 * @Description: 编辑系统用户
 * @Author: 琢磨先生
 * @Date: 2023-03-19 10:54:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-12 11:59:27
-->
<template>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="700px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="姓名" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="登录账号" prop="account">
        <el-col :span="10">
          <el-input
            v-model="form.account"
            placeholder
            :disabled="!!form.id"
          ></el-input>
        </el-col>
        <div class="help-block">支持中文、数字、字母及下划线'_'组合</div>
      </el-form-item>
      <el-form-item label="手机号" prop="mobilePhone">
        <el-col :span="10">
          <el-input v-model="form.mobilePhone" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.sex">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.enabled">
          <el-radio :label="true">正常</el-radio>
          <el-radio :label="false">禁用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="登录密码">
        <el-col :span="10">
          <el-input v-model="form.pwd" placeholder="登陆密码"></el-input>
        </el-col>
        <div class="help-block">新增用户未填写登录密码则系统自动默认指定</div>
      </el-form-item>

      <el-form-item label="角色">
        <el-checkbox-group v-model="form.roleIds">
          <el-checkbox
            :label="item.value"
            v-for="(item, index) in role_options"
            :key="index"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="管理分部">
        <el-checkbox-group v-model="form.attributeIds">
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in branch_options"
            :key="index"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import role_api from "@/http/role_api.js";
import branch_api from "@/http/branch_api.js";

export default {
  data() {
    return {
      visibleDialog: false,
      saving: false,
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        account: [{ required: true, message: "请输入", trigger: "blur" }],
        mobilePhone: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      title: "",
      role_options: [], //角色
      branch_options: [], //分部
    };
  },
  props: ["item"],
  emits: ["success", "closed"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visibleDialog = true;
          this.form = Object.assign({}, this.item);
          this.title = this.form.id ? "修改" : "新增";
          if (!this.form.id) {
            this.form.account = this.form.mobilePhone;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    role_api.get_select().then((res) => {
      if (res.code == 0) {
        this.role_options = res.data;
      }
    });

    branch_api.get_select().then((res) => {
      if (res.code == 0) {
        this.branch_options = res.data;
      }
    });
  },
  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },
    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/user/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visibleDialog = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
