<template>
  <el-button type="primary" size="small" icon="plus" round @click="onOpen">新增</el-button>
  <el-drawer
    title="从OA导入人员"
    v-model="visibleDrawer"
    size="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-table :data="persons" style="width: 100%" border v-loading="loading">
      <el-table-column>
        <template #header>
          <div class="flex-row">
            <el-input v-model="q" placeholder="姓名、手机号" style="width:260px;margin-right:20px;"></el-input>
            <el-button type="primary" @click="loadOAPerson" icon="search" round>查询</el-button>
          </div>
          <!-- <el-form :inline="true">
            <el-form-item label>
              <el-input v-model="q" placeholder="姓名、手机号" style="width:200px;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="loadOAPerson" icon="search" round>查询</el-button>
            </el-form-item>
          </el-form>-->
        </template>
        <template #default="scope">{{scope.row.chsName}}({{scope.row.mobilePhone}})</template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button type="primary" link @click="onImport(scope.row)">导入</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
  <edit-user :item="current_item" @success="addSuccess"></edit-user>
</template>

<script>
import EditUser from "./edit_user.vue";
export default {
  components: {
    EditUser,
  },
  data() {
    return {
      visibleDrawer: false,
      loading: false,
      q: "",
      persons: [],
      current_item: null,
    };
  },
  emits: ["success"],
  created() {},
  methods: {
    /**
     *
     */
    onOpen() {
      this.visibleDrawer = true;
    },

    /**
     *
     */
    onImport(item) {
      this.current_item = {
        mobilePhone: item.mobilePhone,
        oaId: item.id,
        name: item.chsName,
        sex: item.sex,
        enabled: true,
      };
    },

    /**
     *
     */
    loadOAPerson() {
      this.loading = true;
      this.$http
        .get("admin/v1/user/oa_person?q=" + this.q)
        .then((res) => {
          if (res.code == 0) {
            this.persons = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 添加成功
     */
    addSuccess() {
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>